body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: auto;
  background-color: #f8f9fa;

  router-outlet + * {
    position: absolute;
    height: calc(100% - 75px);
    width: 100%;
    top: 75px;
    overflow: auto;
  }

  .minimal-container {
    max-width: 1100px;
    margin: 0 auto;
  }
  .component-container,
  .minimal-container {
    padding: 20px;
    margin-bottom: 20px;
  }

  .spacer {
    margin-bottom: 20px;
  }
}

.pull-right {
  float: right;
}

.mt10 {
  margin-top: 10px !important;
}
.mr10 {
  margin-right: 10px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.ml10 {
  margin-left: 10px !important;
}

p.separator {
  width: 100%;
  margin: 15px 0px !important;
  border-bottom: 1px solid black;
}
