p-toolbar {
  span.title {
    color: white;
    font-size: 1.5em;
  }
}

p-sidebar {
  .p-sidebar {
    overflow: auto;

    .p-sidebar-content {
      h2 {
        margin-top: 0px;
      }
    }
  }
}

p-listbox.rows {
  .p-listbox-item {
    flex-flow: wrap;
  }
}
